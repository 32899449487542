body {
  background: #161f26;
  overflow-y: hidden;
}

body .game-screen, .team-modal .team-modal-content {
  font-family: "Chakra Petch", "Roboto", "Helvetica", "Arial", sans-serif;
}

.game-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  background-color: #161f26;
}

.background {
  margin: 0 auto;
  width: 1440px;
  height: 1021px;
  background-image: url("../public/background.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
}

.room {
  position: absolute;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 325px;
  height: 192px;
}

.room img {
  width: 100%;
}

.room:hover {
  border-color: yellow;
  box-shadow: 0 0 10px yellow;
}

.room-1 {
  top: 176px;
  left: 192px;
}
.room-2 {
  top: 176px;
  left: 552px;
}
.room-3 {
  top: 176px;
  left: 914px;
}
.room-4 {
  top: 416px;
  left: 192px;
}
.room-5 {
  top: 416px;
  left: 552px;
}
.room-6 {
  top: 406px;
  left: 914px;
}
.room-7 {
  top: 642px;
  left: 192px;
}
.room-8 {
  top: 652px;
  left: 552px;
}
.room-9 {
  top: 642px;
  left: 924px;
}

.room-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.room-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #282c34;
  color: white;
  height: 64px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-left .text-center {
  display: flex;
  align-items: center;
  margin: 5px 0;
  background: #ffffff0f;
  padding: 0 10px;
  border-radius: 10px;
  height: 40px;
  font-size: 13px;
  cursor: pointer;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.menu-button {
  display: none;
}

.drawer-content {
  padding: 20px 5px;
  background-color: #333;
  color: white;
  height: 100%;
}

.balance-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.navbar-button {
  color: white;
  border-color: white;
  font-size: 14px;
  padding: 5px 10px;
}

.drawer-content .mobile-quests-button {
  width: 100%;
  color: #fff;
  border-color: #fff;
  margin-bottom: 15px;
}

.wallet-adapter-button {
  margin-left: 10px;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  outline: none;
}

.room-option {
  text-align: center;
}

.room-image {
  width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.footer {
  margin-top: 50px;
}

.footer a {
  color: white;
  text-decoration: dotted underline;
}

.MuiPaper-elevation {
  overflow: visible !important;
}

.team-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-modal-content {
  background-size: cover;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  color: white;
  max-height: 90vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.team-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.team-member-image {
  width: 175px;
  height: auto;
  border-radius: 10px;
  margin-right: 15px;
}

.team-member-info {
  flex-grow: 1;
  text-align: left;
  padding-right: 20px;
}

.team-member-info button {
  border-radius: 0;
}

@media (max-width: 768px) {
  .navbar-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-right .desktop-only,
  .navbar-left .desktop-only {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .modal-box {
    max-height: 80vh;
  }

  .modal-content {
    overflow-y: auto;
    max-height: calc(100% - 32px);
  }

  .balance-item {
    align-items: center;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .navbar-right button {
    padding: 5px;
    font-size: 13px;
  }

  .navbar-right {
    flex-wrap: wrap;
    gap: 5px;
  }

  .navbar-button {
    font-size: 12px;
    padding: 3px 6px;
  }

  .team-modal-content {
    width: 100%;
    height: auto;
  }
}
